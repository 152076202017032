import type { HeadlineResponse } from '@on3/api';
import { On3IconPremiumPlus } from '@on3/icons';
import { toFriendlyDate } from '@on3/ui-lib/src/utils/date';
import clsx from 'clsx';
import { ResizedImage } from 'components/Image/ResizedImage';
import Link from 'next/link';
import { useMemo } from 'react';

import styles from './ArticleCover.module.scss';

interface IExtendedArticleProps {
  categoryColor?: string;
  titleColor?: string;
  authorColor?: string;
  mode?: 'dark' | 'light';
  page?: 'network' | 'channel' | 'team';
  index?: number;
  item?: HeadlineResponse;
}

export const ArticleCover = ({
  index,
  categoryColor,
  titleColor,
  authorColor,
  item,
}: IExtendedArticleProps) => {
  const {
    featuredImage,
    title,
    fullUrl,
    author,
    postDate,
    primaryCategory,
    isPremium,
  } = item || {};

  let baseUrl = primaryCategory?.fullUrl;

  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  const categoryColorStyle = useMemo(() => {
    return { color: categoryColor };
  }, [categoryColor]);
  const titleColorStyle = useMemo(() => {
    return { color: titleColor };
  }, [titleColor]);
  const authorColorStyle = useMemo(() => {
    return { color: authorColor };
  }, [authorColor]);

  return (
    <article>
      {featuredImage && (
        <Link className={styles.imagelink} href={fullUrl || '/'}>
          <ResizedImage
            className={clsx({
              [styles.image]: true,
              [styles.imageSecondary]: index,
            })}
            {...featuredImage}
            fit="cover"
            gravity="0.5x0.3"
            height={204}
            loading="eager"
            quality={90}
            width={413}
          />
        </Link>
      )}
      <Link
        className={styles.category}
        href={`${baseUrl}/news/`}
        style={categoryColorStyle}
      >
        {primaryCategory?.name}
      </Link>
      <Link
        className={styles.titlelink}
        href={fullUrl || '/'}
        style={titleColorStyle}
      >
        <h2
          className={clsx({
            [styles.title]: true,
            [styles.titleOrder]: index,
          })}
        >
          {title}
        </h2>
      </Link>

      <div className={styles.authorblock} style={authorColorStyle}>
        <Link className={styles.author} href={`/user/${author?.niceName}`}>
          {author?.name}
        </Link>
        <span className={styles.time}>
          <span className={styles.bullet}>&#8226;</span>
          {toFriendlyDate(postDate)}
        </span>
        {isPremium && (
          <On3IconPremiumPlus
            className={styles.on3Icon}
            height={40}
            width={14}
          />
        )}
      </div>
    </article>
  );
};
