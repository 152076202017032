import {
  type HeadlineResponse,
  type NilHundredResponseNil100FilterResponseRelatedPagedData,
  PersonSportRankingResponseRelatedRankingResponseRelatedPagedData,
} from '@on3/api';
import { On3IconSoftballLogoFull } from '@on3/icons';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { PlayerRankingsModule } from 'components/Db/Hub/Modules/PlayerRankingsModule';
import { CoverList } from 'components/Headlines/ArticleCover/CoverList';
import { FeedList } from 'components/Headlines/ArticleFeed/FeedList';
import { FeedListAlternate } from 'components/Headlines/ArticleFeedAlternate/FeedListAlternate';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { Nil100Widget } from 'components/Nil/Nil100Widget';
import { Text } from 'components/UI/Typography/Text';
import { useAds } from 'utils/hooks/useAds';

import styles from './SoftballAmericaHome.module.scss';

const topStoriesViewAll = [
  {
    url: '/softball/news/',
    text: `View All Softball News`,
    title: `Top Stories`,
  },
];
const collegeViewAll = [
  {
    url: '/softball/category/college/news/',
    text: `View All College News`,
    title: `College Softball News`,
  },
];
const recruitingViewAll = [
  {
    url: '/softball/category/recruiting/news/',
    text: `View All Recruiting News`,
    title: `Recruiting Softball News`,
  },
];
const proViewAll = [
  {
    url: '/softball/category/pro/news/',
    text: `View All Pro News`,
    title: `Pro Softball News`,
  },
];

export interface SoftballAmericaHomeProps {
  latestArticles?: HeadlineResponse[] | null;
  collegeArticles?: HeadlineResponse[] | null;
  topStories?: HeadlineResponse[] | null;
  recruitingNews?: HeadlineResponse[] | null;
  proNews?: HeadlineResponse[] | null;
  nil100?: NilHundredResponseNil100FilterResponseRelatedPagedData;
  topSoftballAthletes?: PersonSportRankingResponseRelatedRankingResponseRelatedPagedData;
}

export const SoftballAmericaHome = ({
  latestArticles,
  collegeArticles,
  nil100,
  topSoftballAthletes,
  topStories,
  recruitingNews,
  proNews,
}: SoftballAmericaHomeProps) => {
  useAds({ pageType: 'home', tags: 'womens-sports' });
  const { list } = nil100 || {};
  const showRankings = (topSoftballAthletes?.list?.length ?? 0) > 0;
  const coverArticles = topStories?.slice(0, 3);
  const { currentSite } = useSite();
  const primaryColor = currentSite?.primaryColor || '#333333';
  const siteColor = currentSite?.linkColor || '#fe3b1f';

  return (
    <>
      {!!coverArticles?.length && (
        <CoverList
          authorColor="#fff"
          backgroundColor={primaryColor}
          categoryColor={siteColor}
          list={coverArticles}
          titleColor="#fff"
        />
      )}
      <HomeLayout>
        <Text className={styles.metaText}>
          <On3IconSoftballLogoFull
            className={styles.metaIcon}
            height={36}
            width={80}
          />
          The Top Source for Softball Stories, Rankings, and News
        </Text>

        <section className={styles.feed}>
          {!latestArticles?.length || (
            <FeedList
              list={latestArticles.slice(1, 12)}
              section="Latest Stories"
              viewAllLinks={topStoriesViewAll}
            />
          )}
          <MobileMPU id={'mobile_mpu_top'} incontent />
        </section>
        <aside className={styles.sidebarright}>
          <MPU id={'mpu_top'} />
          {!!list?.length && (
            <Nil100Widget gender="womens" list={list?.slice(0, 5)} />
          )}
          <MPU className={styles.sticky} id={'mpu_middle'} />
          <MobileMPU id={'mobile_mpu_middle'} incontent />
          {showRankings && (
            <PlayerRankingsModule
              playerData={topSoftballAthletes}
              selectedSport="softball"
            />
          )}
        </aside>
        <aside className={styles.sidebarleft}>
          <FeedListAlternate
            list={collegeArticles?.slice(0, 5)}
            section="College News"
            viewAllLinks={collegeViewAll}
          />
          <FeedListAlternate
            list={recruitingNews?.slice(0, 5)}
            section="Recruiting News"
            viewAllLinks={recruitingViewAll}
          />
          <FeedListAlternate
            list={proNews?.slice(0, 5)}
            section="Pro News"
            viewAllLinks={proViewAll}
          />
        </aside>
      </HomeLayout>
    </>
  );
};
